import {Navigate, RouteObject} from 'react-router-dom';
import {lazyAdminRoute} from '@common/admin/routes/lazy-admin-route';

export const appAdminRoutes: RouteObject[] = [
  {
    path: '',
    children: [
      {path: 'tickets', lazy: () => lazyAdminRoute('AdminTicketReport')},
      {path: 'envato', lazy: () => lazyAdminRoute('EnvatoReport')},
      {
        path: 'search',
        lazy: () => lazyAdminRoute('AdminSearchReport'),
        children: [
          {
            index: true,
            element: <Navigate to="failed" replace />,
          },
          {
            path: 'failed',
            lazy: () => lazyAdminRoute('FailedSearchesReport'),
          },
          {
            path: 'popular',
            lazy: () => lazyAdminRoute('PopularSearchesReport'),
          },
          {
            path: 'articles',
            lazy: () => lazyAdminRoute('ArticlesReport'),
          },
        ],
      },
      {path: 'visitors', lazy: () => lazyAdminRoute('AdminVisitorsReport')},
      {index: true, element: <Navigate to="tickets" replace />},
    ],
  },
  // help center
  {
    path: 'hc',
    element: <Navigate to="/hc/arrange" replace />,
  },
  {
    path: 'hc/arrange',
    lazy: () => lazyAdminRoute('HcCategoryManager'),
  },
  {
    path: 'hc/arrange/categories/:categoryId',
    lazy: () => lazyAdminRoute('HcCategoryManager'),
  },
  {
    path: 'hc/arrange/sections/:sectionId',
    lazy: () => lazyAdminRoute('HcArticleManager'),
  },
  {
    path: 'articles',
    lazy: () => lazyAdminRoute('ArticleDatatablePage'),
  },
  {
    path: 'articles/new',
    lazy: () => lazyAdminRoute('CreateArticlePage'),
  },
  {
    path: 'articles/:articleId/edit',
    lazy: () => lazyAdminRoute('UpdateArticlePage'),
  },
  // edit article
  {
    path: 'hc/arrange/sections/:sectionId/articles/:articleId/edit',
    lazy: () => lazyAdminRoute('UpdateArticlePage'),
  },
  {
    path: 'hc/arrange/categories/:categoryId/articles/:articleId/edit',
    lazy: () => lazyAdminRoute('UpdateArticlePage'),
  },
  {
    path: 'hc/articles/:articleId/edit',
    lazy: () => lazyAdminRoute('UpdateArticlePage'),
  },
  // create article
  {
    path: 'hc/arrange/sections/:sectionId/articles/new',
    lazy: () => lazyAdminRoute('CreateArticlePage'),
  },
  {
    path: 'hc/arrange/categories/:categoryId/articles/new',
    lazy: () => lazyAdminRoute('CreateArticlePage'),
  },
  {
    path: 'hc/articles/new',
    lazy: () => lazyAdminRoute('CreateArticlePage'),
  },
  // tickets
  {
    path: 'ticket-categories',
    lazy: () => lazyAdminRoute('TicketCategoryDatatable'),
  },
  // canned replies
  {
    path: 'saved-replies',
    lazy: () => lazyAdminRoute('CannedRepliesDatatablePage'),
  },
  // triggers
  {
    path: 'triggers',
    lazy: () => lazyAdminRoute('TriggersDatatablePage'),
  },
  {
    path: 'triggers/new',
    lazy: () => lazyAdminRoute('CreateTriggerPage'),
  },
  {
    path: 'triggers/:triggerId/edit',
    lazy: () => lazyAdminRoute('CreateTriggerPage'),
  },
];

import {
  IAppearanceConfig,
  MenuSectionConfig,
  SeoSettingsSectionConfig,
} from '@common/admin/appearance/types/appearance-editor-config';
import {message} from '@ui/i18n/message';
import {lazyAdminRoute} from '@common/admin/routes/lazy-admin-route';

export const AppAppearanceConfig: IAppearanceConfig = {
  preview: {
    defaultRoute: '/',
    navigationRoutes: ['/'],
  },
  sections: {
    'landing-page': {
      label: message('Landing page'),
      position: 0,
      previewRoute: '/',
      routes: [
        {
          path: 'landing-page',
          lazy: () => lazyAdminRoute('HcLandingPageAppearanceEditor'),
        },
      ],
    },
    'new-ticket': {
      label: message('New ticket page'),
      position: 1,
      previewRoute: 'hc/tickets/new',
      routes: [
        {
          path: 'new-ticket',
          lazy: () => lazyAdminRoute('NewTicketPageAppearanceForm'),
          children: [
            {
              index: true,
              lazy: () => lazyAdminRoute('NewTicketPageAppearance'),
            },
            {
              path: 'sidebar',
              lazy: () => lazyAdminRoute('SidebarTipSection'),
            },
          ],
        },
      ],
    },
    // missing label will get added by deepMerge from default config
    // @ts-ignore
    menus: {
      config: {
        positions: [
          'header',
          'agent-mailbox',
          'landing-page-navbar',
          'landing-page-footer',
        ],
        availableRoutes: [
          '/hc',
          '/hc/tickets',
          '/hc/tickets/new',
          '/agent/tickets',
          '/admin/hc/arrange',
          '/admin/hc/articles',
          '/admin/triggers',
        ],
      } as MenuSectionConfig,
    },
    // @ts-ignore
    'seo-settings': {
      config: {
        pages: [
          {
            key: 'landing-page',
            label: message('Landing page'),
          },
          {
            key: 'article-page',
            label: message('Article page'),
          },
          {
            key: 'category-page',
            label: message('Category page'),
          },
          {
            key: 'search-page',
            label: message('Search page'),
          },
        ],
      } as SeoSettingsSectionConfig,
    },
  },
};

import {queryOptions, useQuery} from '@tanstack/react-query';
import {apiClient} from '@common/http/query-client';
import {PaginatedBackendResponse} from '@common/http/backend-response/pagination-response';
import {Ticket} from '@app/agent/ticket';
import {buildMailboxPrams} from '@app/agent/use-mailbox-params';
import {useSearchParams} from 'react-router-dom';
import {PaginateTicketsParams} from '@app/agent/agent-ticket-page/user-details-sidebar/requests/use-tickets';

export function useAgentTicketListData() {
  const [searchParams] = useSearchParams();
  return useQuery(agentTicketListDataQueryOptions(searchParams));
}

export function agentTicketListDataQueryOptions(searchParams: URLSearchParams) {
  const params = {
    ...buildMailboxPrams(searchParams),
    loader: 'ticketTable' as const,
  };
  return queryOptions({
    queryKey: ['tickets', params],
    queryFn: () => fetchTickets(params),
  });
}

function fetchTickets(params: PaginateTicketsParams) {
  return apiClient
    .get<PaginatedBackendResponse<Ticket>>('tickets', {params})
    .then(response => response.data);
}
